<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-card title="선임명 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :editable="editable"
                  disabled
                  label="LBL0010222"
                  name="선임명"
                  v-model="seniorDismissalStatus.seniorName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!--관련 법규-->
                <c-multi-select
                  disabled
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="REGULATION_BILL_API_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="LBL0010215"
                  name="relatedLaws"
                  v-model="seniorDismissalStatus.relatedLaws">
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 자격요건 -->
                <c-textarea
                  disabled
                  :editable="editable"
                  :rows="2"
                  name="qualifiyRequire"
                  label="LBL0010228"
                  v-model="seniorDismissalStatus.qualifiyRequire"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 관련근거 -->
                <c-textarea
                  disabled
                  :editable="editable"
                  name="relatedReason"
                  label="LBL0010229"
                  :rows="2"
                  v-model="seniorDismissalStatus.relatedReason"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 수행역할 -->
                <c-textarea
                  disabled
                  :editable="editable"
                  name="seniorReason"
                  label="LBL0010230"
                  :rows="2"
                  v-model="seniorDismissalStatus.seniorReason"
                ></c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <c-card title="선임 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  disabled
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="seniorDismissalStatus.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!--부서 & 선임자-->
                <c-field 
                  disabled
                  :editable="editable"
                  :data="seniorDismissalStatus"
                  type="dept_user" 
                  deptValue="seniorDeptCd"
                  label="LBL0010223" 
                  name="seniorUserId" 
                  v-model="seniorDismissalStatus.seniorUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!--선임일-->
                <c-datepicker
                  disabled
                  :editable="editable"
                  label="LBL0010217"
                  name="seniorDate"
                  v-model="seniorDismissalStatus.seniorDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <!--해임여부-->
                    <c-radio
                      disabled
                      :editable="editable"
                      :comboItems="dismissalFlagItems"
                      label="LBL0010224"
                      name="dismissalFlag"
                      v-model="seniorDismissalStatus.dismissalFlag">
                    </c-radio>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <!--해임일-->
                    <c-datepicker
                      disabled
                      :editable="editable"
                      label="LBL0010219"
                      :start="seniorDismissalStatus.seniorDate"
                      name="dismissalDate"
                      v-model="seniorDismissalStatus.dismissalDate"
                    />
                  </div>
                </div>
              </div>
            </template>
          </c-card>
          <c-card title="자격증 정보" class="cardClassDetailForm q-mt-sm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!--관련자격증-->
                <c-text
                  disabled
                  :editable="editable"
                  label="LBL0010225"
                  name="relatedCertificate"
                  v-model="seniorDismissalStatus.relatedCertificate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!--자격증번호-->
                <c-text
                  disabled
                  :editable="editable"
                  label="LBL0010226"
                  name="certificateNo"
                  v-model="seniorDismissalStatus.certificateNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!--자격취득일-->
                <c-datepicker
                  disabled
                  :editable="editable"
                  label="LBL0010227"
                  name="certificateAcquiDate"
                  v-model="seniorDismissalStatus.certificateAcquiDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!--관련 선해임자료 및 자격증-->
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="false"
                  label="LBL0010232">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'senior-dismissal-status-history-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        seniorStatusId: '',
      }),
    },
  },
  data() {
    return {
      seniorDismissalStatus: {
        seniorStatusId: '',  // 선해임현황 key
        seniorId: '',  // 선해임명 key
        plantCd: '',  // 사업장
        seniorUserId: '',  // 선임 user
        seniorDate: '',  // 선임일
        relatedCertificate: '',  // 관련자격증
        certificateNo: '',  // 자격증번호
        certificateAcquiDate: '',  // 자격취득일
        relatedReason: '',  // 관련근거
        seniorReason: '',  // 선임사유
        groupId: '',  // 일련번호 키
        sysRevision: '',  // 시스템 개정번호
        revisionNum: '',  // 개정번호
        dismissalFlag: 'N',  // 해임여부
        dismissalDate: '',  // 해임일
        dismissalFlagSave: 'N',  // 해임여부 (저장된 정보)
        dismissalDateSave: '',  // 해임일 (저장된 정보)
        revisionContent: '',  // 개정사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        eduStatuses: [],
        seniorHistory: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'DISMISSAL_ATTACH',
        taskKey: '',
      },
      dismissalFlagItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.seniorStatusId)
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.senior.status.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.seniorStatusId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.seniorDismissalStatus, _result.data);
          this.$set(this.seniorDismissalStatus, 'dismissalFlagSave', _result.data.dismissalFlag)
          this.$set(this.seniorDismissalStatus, 'dismissalDateSave', _result.data.dismissalDate)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
  }
};
</script>